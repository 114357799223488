import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { UrlSerializer } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './layout/footer/footer.module';
import { HeaderModule } from './layout/header/header.module';
import { TokenDataService } from './shared/services/token-data.service';
import { CustomUrlSerializer } from './custom-url-serializer';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { ProgressBarModule } from './progress-bar/progress-bar.module';
import { OffersService } from './offers/offers.service';
import { LoaderModule } from './shared/components/loader/loader.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { PushNotificationService } from './shared/services/push-notification.service';
import { firebaseEnvironment } from 'src/environments/environment';
import { ExternalRedirectComponent } from './external-redirect/external-redirect.component';

@NgModule({
  declarations: [AppComponent, ExternalRedirectComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HeaderModule,
    FooterModule,
    ToastrModule.forRoot({ timeOut: 2000 }),
    ProgressBarModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebaseEnvironment.firebase),
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    TokenDataService,
    Title,
    PushNotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
