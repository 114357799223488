import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-redirect',
  template: '',
})
export class ExternalRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const leadId = this.route.snapshot.paramMap.get('leadId');
    console.log('here is your lead id', leadId);
    if (leadId) {
      window.location.href = `https://opendoor.digitalwavesolutions.co.uk/journeys/od-form-builder/?formID=17&lead_id=${leadId}`;
    } else {
      console.error('Lead ID not found in the route!');
    }
  }
  //   window.location.href =
  //     'https://opendoor.digitalwavesolutions.co.uk/journeys/od-form-builder/?formID=17&lead_id=5fc1667af9093b4c9f39052c86f56ee0';
  // }
}
