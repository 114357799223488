import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginCheckGuard } from './shared/guards/login-check.guard';
import { AuthCheckGuard } from './shared/guards/auth-check.guard';
import { FirstLoginGuard } from './shared/guards/first-login.guard';
import { BasicPlanGuard } from './shared/guards/basic-plan.guard';
import { ExternalRedirectComponent } from './external-redirect/external-redirect.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'r/:id',
    loadChildren: () =>
      import('./redirect/redirect.module').then((m) => m.RedirectModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./auth/signin/signin.module').then((m) => m.SigninModule),
    canActivate: [LoginCheckGuard],
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/signup/signup.module').then((m) => m.SignupModule),
    canActivate: [LoginCheckGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/forgot-paswd/forgot-paswd.module').then(
        (m) => m.ForgotPaswdModule
      ),
    canActivate: [LoginCheckGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/reset-pswd/reset-pswd.module').then(
        (m) => m.ResetPswdModule
      ),
  },
  {
    path: 'confirm-account',
    loadChildren: () =>
      import('./auth/reset-pswd/reset-pswd.module').then(
        (m) => m.ResetPswdModule
      ),
  },
  {
    path: 'confirm-email',
    loadChildren: () =>
      import('./auth/confirm-email/confirm-email.module').then(
        (m) => m.ConfirmEmailModule
      ),
    canActivate: [LoginCheckGuard],
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./services/services.module').then((m) => m.ServicesModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then((m) => m.NewsModule),
  },
  {
    path: 'career',
    loadChildren: () =>
      import('./career/career.module').then((m) => m.CareerModule),
  },
  {
    path: 'offers',
    loadChildren: () =>
      import('./offers/offers.module').then((m) => m.OffersModule),
  },
  {
    path: 'rewards',
    loadChildren: () =>
      import('./rewards/rewards.module').then((m) => m.RewardsModule),
    canActivate: [AuthCheckGuard],
  },
  {
    path: 'terms-service',
    loadChildren: () =>
      import('./terms-policy/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./terms-policy/policy/policy.module').then((m) => m.PolicyModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthCheckGuard],
  },
  {
    path: 'lifestyle-questionnaire',
    loadChildren: () =>
      import('./life-questions/life-questions.module').then(
        (m) => m.LifeQuestionsModule
      ),
    canActivate: [AuthCheckGuard],
  },
  {
    path: 'get-rewards',
    loadChildren: () =>
      import('./get-rewards/get-rewards.module').then(
        (m) => m.GetRewardsModule
      ),
    canActivate: [FirstLoginGuard],
  },
  {
    path: 'upgrade-plan',
    loadChildren: () =>
      import('./upgrade-plan/upgrade-plan.module').then(
        (m) => m.UpgradePlanModule
      ),
    canActivate: [BasicPlanGuard],
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./upgrade-payment/upgrade-payment.module').then(
        (m) => m.UpgradePaymentModule
      ),
    canActivate: [BasicPlanGuard],
  },
  {
    path: 'acceptpayment',
    loadChildren: () =>
      import('./upgrade-payment/upgrade-payment.module').then(
        (m) => m.UpgradePaymentModule
      ),
    canActivate: [BasicPlanGuard],
  },
  {
    path: 'cancelled',
    loadChildren: () =>
      import('./upgrade-payment/upgrade-payment.module').then(
        (m) => m.UpgradePaymentModule
      ),
    canActivate: [BasicPlanGuard],
  },
  {
    path: 'declined',
    loadChildren: () =>
      import('./upgrade-payment/upgrade-payment.module').then(
        (m) => m.UpgradePaymentModule
      ),
    canActivate: [BasicPlanGuard],
  },
  {
    path: 'exception',
    loadChildren: () =>
      import('./upgrade-payment/upgrade-payment.module').then(
        (m) => m.UpgradePaymentModule
      ),
    canActivate: [BasicPlanGuard],
  },
  {
    path: 'third-party-contact',
    loadChildren: () =>
      import('./third-party-contact/third-party-contact.module').then(
        (m) => m.ThirdPartyContactModule
      ),
  },
  {
    path: 'business-energy',
    loadChildren: () =>
      import('./third-party-contact/third-party-contact.module').then(
        (m) => m.ThirdPartyContactModule
      ),
  },
  {
    path: 'adt-offer',
    loadChildren: () =>
      import('./adt-offer/adt-offer.module').then((m) => m.AdtOfferModule),
  },
  {
    path: 'campaign/:cid/:oid/:mid',
    loadChildren: () =>
      import('./campaign/campaign.module').then((m) => m.CampaignModule),
    canActivate: [AuthCheckGuard],
  },
  { path: 's', component: ExternalRedirectComponent },
  { path: 's/:leadId', component: ExternalRedirectComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
